import { StyleConstants } from './constants/styles';
import { debounce } from './generic/helpers/Debounce';

type ScreenType = 'mobile' | 'desktop';

(() => {
	let screenType: ScreenType | undefined;
	let tearDownFunction: () => void | undefined;

	const mobileNavButton: HTMLButtonElement = document.getElementById('side-nav-mobile-menu-controls-button') as HTMLButtonElement;
	const secondaryNav: HTMLUListElement = document.getElementById('sub-navigation-list') as HTMLUListElement;

	function setupDesktopNav(): () => void {

		mobileNavButton.setAttribute('aria-hidden', 'true');
		mobileNavButton.setAttribute('tabindex', '-1');
		mobileNavButton.removeAttribute('aria-controls');
		mobileNavButton.removeAttribute('aria-expanded');
		mobileNavButton.classList.add('hide');
		secondaryNav.classList.remove('open');

		return () => { };
	}

	function setupMobileNav(): () => void {

		let open = false;
		const allAnchors = secondaryNav.querySelectorAll('a');
		const lastElement = allAnchors[allAnchors.length - 1];

		function escapeKey(event: KeyboardEvent): boolean {
			if (event.keyCode === 27) {// ESC
				setOpen(false);
				mobileNavButton.focus();
			}
			return true;
		}

		function lastElementBlur(): void {
			setOpen(false);
		}

		function setOpen(value: boolean): void {
			open = value;
			mobileNavButton.setAttribute('aria-expanded', value.toString());
			if (value) {
				mobileNavButton.setAttribute('aria-label', 'Close sub-navigation');
				secondaryNav.classList.add('open');
				secondaryNav
					.querySelectorAll('a')
					.forEach(a => a.removeAttribute('tabindex'));
				secondaryNav.removeAttribute('aria-hidden');
				document.addEventListener('keyup', escapeKey);
				lastElement.addEventListener('blur', lastElementBlur);
			} else {
				mobileNavButton.setAttribute('aria-label', 'Open sub-navigation');
				secondaryNav.classList.remove('open');
				secondaryNav
					.querySelectorAll('a')
					.forEach(a => a.setAttribute('tabindex', '-1'));
				secondaryNav.setAttribute('aria-hidden', 'true');
				document.removeEventListener('keyup', escapeKey);
				lastElement.removeEventListener('blur', lastElementBlur);
			}
		}

		function mobileNavToggle(): void {
			setOpen(!open);
		}

		mobileNavButton.removeAttribute('aria-hidden');
		mobileNavButton.removeAttribute('tabindex');
		mobileNavButton.classList.remove('hide');
		mobileNavButton.setAttribute('aria-controls', 'sub-navigation-list');
		setOpen(false);

		mobileNavButton.addEventListener('click', mobileNavToggle);
		lastElement.addEventListener('blur', lastElementBlur);

		return function tearDownMobileNav(): void {
			mobileNavButton.removeEventListener('click', mobileNavToggle);
			setOpen(true);
			document.removeEventListener('keyup', escapeKey);
			lastElement.removeEventListener('blur', lastElementBlur);
		}
	}

	function setScreenType(): void {

		function detectScreenType(): ScreenType {
			const width = window.innerWidth;
			const isMobile = width && width < StyleConstants.SubMenuBreakPoint;
			return isMobile ? 'mobile' : 'desktop';
		}

		const newScreenType = detectScreenType();

		// only act if it's changed
		if (screenType !== newScreenType) {

			if (tearDownFunction) {
				tearDownFunction();
			}

			tearDownFunction = newScreenType === 'desktop'
				? setupDesktopNav()
				: setupMobileNav();

			screenType = newScreenType;
		}
	}


	setScreenType();
	const debouncedSetScreenType = debounce(setScreenType, 100);
	window.addEventListener('resize', debouncedSetScreenType);
})();
